<template>
  <div class="time-tick">
    <ul class="bg-gray-50 p-2 sm:p-5 xl:p-6">
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              持续深耕，不断更新追求极致
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-cyan-400">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <circle cx="6" cy="6" r="11" fill="none" stroke="currentColor" stroke-width="2"></circle>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2023
            </div>
            <div class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              <div class="hide-text">
                10月，璐付供应链平台V1.0上线<br />
                8月，与广东互视达电子科技有限公司合作成立重庆互视达科技有限公司，正式进入智慧屏领域<br />
                悦礼企福企业福利平台V1.0，墨鱼爱生活权益兑换平台V2.0上线<br />
                7月，悦礼企福（重庆）数字科技有限公司成立，全力开拓福利领域<br />
                3月，璐付重庆总部正式乔迁新址-北滨一路招商江湾城，新的起点，开启新的征程<br />
                2月，虎票票2.0正式上线，全新形象，为客户带来更贴心体验<br />
              </div>
              <p>1月，成立重庆璐付科技有限公司成都分公司</p>
            </div>
          </a>
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              丰富场景，带来更多的可能性
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2022
            </div>
            <div class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              <div class="hide-text">10月，荣获”国家高新技术企业”认证</div>
              <div class="hide-text">8月，全资子公司璐付（深圳）数字科技有限公司成立</div>
              <div class="hide-text">5月，墨鱼爱生活积分平台1.0上线</div>
              <p>1月， 数字化营销福利电影票平台“虎票票”1.0上线</p>
            </div>
          </a>
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              全面升级，为客户带来V3.0的体验
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2020
            </div>
            <p class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              4月，数字化营销开放平台3.0上线，升级营销福利实现数字化智能营销全面转型
            </p></a
          >
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              扩张与壮大，实现自我突破
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2019
            </div>
            <div class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              <div class="hide-text">
                2019年8月，打造并完善实物供应链体系，丰富全类目产品数字化，赋能企业营销及福利场景<br />
                2019年5月，全资子公司上海璐付信息技术有限公司成立<br />
                2019年4月，数字化加油“油箱GAI”1.0 上线
              </div>
              <p>成立全资子公司上海璐付信息技术有限公司，提出璐付全国化战略</p>
            </div>
          </a>
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              勇敢尝试，挑战更多不可能
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2018
            </div>
            <div class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              <div class="hide-text">6月，成都知行博意科技有限公司成立，数字化营销全面开启</div>
              5月，首次启动积分营销业务，将丰富的运营经验赋能于积分营销领域
            </div>
          </a>
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              精确分析，自成一派
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2017
            </div>
            <p class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              2月，依托民生缴费、生活卡券及各类视频会员等打造数字化营销服务平台
            </p></a
          >
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              找准定位，开启新征程
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
                <path d="M 6 18 V 500" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2016
            </div>
            <p class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              10月，一键应用H5平台上线，快速服务移动互联网 APP 自主快速上线缴费服务
            </p></a
          >
        </article>
      </li>
      <li>
        <article>
          <a
            href="javascript:void(0)"
            class="grid md:grid-cols-8 xl:grid-cols-9 items-start relative rounded-xl p-3 sm:p-5 xl:p-6 overflow-hidden hover:bg-white card-line"
            ><h3
              class="font-semibold text-gray-900 md:col-start-3 md:col-span-6 xl:col-start-3 xl:col-span-7 mb-1 ml-9 md:ml-0"
            >
              璐付元年
            </h3>
            <div
              class="md:col-start-1 md:col-span-2 row-start-1 md:row-end-3 flex items-center font-medium mb-1 md:mb-0"
            >
              <svg viewBox="0 0 12 12" class="w-3 h-3 mr-6 overflow-visible text-gray-300">
                <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
                <path d="M 6 -6 V -30" fill="none" stroke-width="2" stroke="currentColor" class="text-gray-200"></path>
              </svg>
              2014
            </div>
            <div class="md:col-start-3 md:col-span-6 xl:col-span-7 ml-9 md:ml-0">
              <div class="hide-text">
                2014年8月，缴费100开放API平台1.0版本上线
                <br />
                2014年7月，移动互联网行业蓬勃发展，重庆璐付科技有限公司成立
              </div>
              乘坐互联网红利的东风，璐付科技成立。
            </div>
          </a>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.time-tick {
  margin-bottom: 40px;
  .card-line {
    .hide-text {
      height: 0;
      transform: opacity;
      opacity: 0;
      transition-duration: 600ms;
    }
  }
  .card-line:hover {
    .hide-text {
      height: 100%;
      opacity: 1;
      transition-duration: 1600ms;
    }
  }
}
</style>
